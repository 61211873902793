import React, { useRef, useEffect } from 'react'
import { useState } from 'react';

function random(min, max) {
  return min + Math.random() * (max + 1 - min);
}

const Canvas = props => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Decrease the remaining seconds by 1 every second
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      let width = window.innerWidth;
      let height = window.innerHeight;

      ctx.canvas.width  = window.innerWidth;
      ctx.canvas.height = window.innerHeight;

      ctx.clearRect(0, 0, width, height);

      //Add stars to a small fraction of the canvas
      const canvasSize = width * height;
      const starsFraction = canvasSize / 300;

      for (let i = 0; i < starsFraction; i++) {
        //Set up random elements
        let xPos = random(2, width - 2);
        let yPos = random(2, height - 2);
        let alpha = random(0.01, 0.1);
        let size = random(0.1, 2);

        //Add stars
        ctx.fillStyle = '#ffffff';
        ctx.globalAlpha = alpha;
        ctx.fillRect(xPos, yPos, size, size);
      }
    }, 100);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  return <canvas ref={canvasRef} {...props} id="test"></canvas>
}

export default Canvas