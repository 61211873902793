import { Button } from "@mui/material"
import { VolumeOff, VolumeUp } from "@mui/icons-material"
import {
    useState, useRef
} from "react";
import "./index.css"

const MusicButton = () => {
    const [isMuted, setMuted] = useState(true);

    // hack? to maintain reference of same audio object accross multiple renders
    let audio = useRef();

    const click = () => {
        // play music on click, and also retain state of audio on unmute
        if (isMuted) {
            if (audio.current == null) {
                audio.current = new Audio("/duet.mp3");
                audio.current.loop = true;
            }

            audio.current.play();
        } else {
            audio.current.pause();
        }

        setMuted(!isMuted);
    }

    return (
        < div className="musicbutton">
            <Button className="musicbutton" style={{color: "#b7f9e0"}}onClick={click}>
                Click me for sound! {isMuted ? <VolumeOff background-color="purple"/> : <VolumeUp />}
            </Button>
        </div >
    );
}

export default MusicButton