import './App.css';
import Canvas from './Canvas';
import { Avatar, Paper } from '@mui/material';
import MusicButton from './MusicButton';
import PhotoAlbum from './PhotoAlbum';

function App() {
  return (
    <div>
      <Paper elevation={20} className="bday" style={{ backgroundColor: "#b7f9e0" }}>
        <h1 style={{ fontSize: 15, padding: 5 }}>Happy Birthday Jing Lei</h1>
      </Paper>
      <Canvas id="canvas">Your browser does not support the HTML canvas tag.</Canvas>
      <MusicButton className="musicbutton" />
      <img src="omori.png" className='gang' />

      <Avatar sx={{ width: 100, height: 100 }} style={{ position: "absolute", bottom: 10, left: 10 }} alt="Jing Lei" src="./jinglei.jpg"></Avatar>
      <PhotoAlbum />
    </div>
  );
}

export default App;
