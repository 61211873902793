import { Paper } from '@mui/material';

import "./index.css"

const Picture = ({ src, text, number, total }) => {
    return (
        <div>
            <Paper elevation={3} style={{ backgroundColor: "#f0eAd6", textAlign: "center" }} className="picturepaper">
                <img src={src} className="albumimg" />
                <p style={{ wordWrap: "break-word" }}>{text}</p>
                <p>[{number}/{total}]</p>
            </Paper>
        </div>
    );
}

export default Picture;