import { Backdrop, Box, Button, Paper } from '@mui/material';
import { useState } from 'react';
import Picture from './Picture';

import "./index.css"
import { NavigateNext, NavigateBefore, Close } from '@mui/icons-material';

const PhotoAlbum = () => {
    const [isOpen, setOpen] = useState(false);
    let [index, setIndex] = useState(0);

    // typescript could probably create a struct for me for this
    // but for now, just do a dumb "map" for pic associated to text
    const images = ["./jingleicar.jpg", "./stevie1.jpg", "./stevie2.jpg", "./jingleibaby.jpg", "./stevie3.jpg", "./jingleisleeping.jpg"];
    const texts = ["Jing Lei was so happy before the accident.",
        "Stevie communicating in another dimension.",
        "Mere seconds before a cuddle attack.",
        "I had to go back in time to take this picture.",
        "Despite being blind, she yearns for the box.",
        "Jing Lei is known for partying hard on her bday."];

    const click = () => {
        setOpen(!isOpen);
    }

    // onClick functions for iterating through pic/texts
    // if you pass in just (index), the state doesnt get updated instantenously
    // and requires another press, this way it works :D?
    const next = () => {
        setIndex((index) => (index + 1) % images.length);
    }

    const back = () => {
        setIndex((index) => (index - 1) % images.length);
    }

    return (
        <div>
            {!isOpen ? <Button style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                bottom: "30px"
            }} onClick={click}>
                <Paper style={{ backgroundColor: "#b7f9e0" }} elevation={20}>
                    <p style={{ padding: 7, color: "#000000" }}>View Basil's Photo Album</p>
                </Paper>
            </Button> : null}
            <Backdrop open={isOpen}>
                {index > 0 ? <Button onClick={back}><NavigateBefore style={{ fontSize: 40, color: "white" }}></NavigateBefore></Button> : <Button disabled onClick={back}><NavigateBefore style={{ fontSize: 40, color: "grey" }}></NavigateBefore></Button>}
                <div style={{ justifyContent: "center" }}>
                    <Picture src={images[index]} text={texts[index]} number={index + 1} total={images.length} className="albumimg" />
                </div>
                {index < images.length - 1 ? <Button onClick={next}><NavigateNext style={{ fontSize: 40, color: "white" }}></NavigateNext></Button> : <Button disabled onClick={next}><NavigateNext style={{ fontSize: 40, color: "grey" }}></NavigateNext></Button>}
                <Button style={{ top: 0, left: 10, position: "absolute" }} onClick={click}><Close style={{ fontSize: 40, color: "white" }}></Close></Button>
            </Backdrop>
        </div>
    );
}

export default PhotoAlbum;

